export default [{ label: 'Razão social', field: 'razaoSocial', type: 'custom' },
  { label: 'CNPJ/CPF', field: 'cadastroFederal', tdClass: 'col-tamanho-cnpj' },
  { label: 'Nº parcelamento', field: 'identificacao', visible: false },
  { label: 'Situação', field: 'situacao', type: 'custom' }, // regular ou irregular
  { label: 'Data pedido', field: 'dataPedido' },
  { label: 'Última parcela lida', field: 'mesUltimaParcela', tdClass: 'alinhar-coluna' },
  { label: 'Parcela', field: 'nroUltimaParcelaDe', type: 'custom' },
  { label: 'Parcelas em atraso', field: 'parcelasEmAtraso', type: 'custom' },
  { label: 'Consultado em', field: 'ultimaExecucao' },
]
